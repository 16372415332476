<template>
  <div>
    <v-container>
      <v-row no-gutters align="start">
        <v-breadcrumbs
          :items="breadcrumbs"
          class="pa-0 pb-2"
          divider=">"
        ></v-breadcrumbs>
        <v-spacer></v-spacer>
        <v-btn
          v-if="enquiry.status == 'New'"
          small
          depressed
          :to="{ name: 'module-sbpm-enquiries' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to List</v-btn
        >
        <v-btn
          v-else-if="enquiry.status == 'Let Only'"
          small
          depressed
          :to="{ name: 'module-sbpm-enquiriesletonly' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to List</v-btn
        >
        <v-btn
          v-else-if="enquiry.status == 'Managed Let'"
          small
          depressed
          :to="{ name: 'module-sbpm-enquiriesmanagedlet' }"
          ><v-icon left>mdi-arrow-left</v-icon>Back to List</v-btn
        >
      </v-row>
      <v-row align="center" no-gutters>
        <v-col cols="auto" class="pr-12">
          <h1>
            {{ enquiry.property_address_line1 }}
            {{ enquiry.landlord_full_name }}
          </h1>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="4">
          <v-card class="mb-6" v-if="enquiry.property_id">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Property</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table class="mb-5">
              <tbody>
                <tr>
                  <td>
                    {{ enquiry.property.property_name }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6" v-if="enquiry.property_id">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Prospective Tenants</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table class="mb-5">
              <tbody v-if="!applications2.length">
                <tr>
                  <td>No Applications</td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr v-for="item in applications2" v-bind:key="item.id">
                  <td>
                    {{ item.applicant_first_name }}
                    {{ item.applicant_last_name }}

                    {{ item.email }}
                    {{ item.phone }}
                  </td>
                  <td class="text-right">
                    <v-btn
                      color="green lighten-4 green--text"
                      depressed
                      small
                      light
                      :to="{
                        name: 'module-sbpm-applications-individual',
                        params: { applicationId: item.id },
                      }"
                    >
                      <v-icon small>mdi-eye</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Details</v-toolbar-title>
              <v-spacer></v-spacer>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="blue lighten-4 blue--text"
                    v-on="on"
                    @click="$refs.enquiryDialog.open(enquiry)"
                  >
                    <v-icon small>mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-simple-table class="mb-5">
              <tbody>
                <tr v-if="enquiry.property_id == null">
                  <td>Address</td>
                  <td>
                    {{ enquiry.property_address_line1 }}
                    {{ enquiry.property_address_line2 }}
                    {{ enquiry.property_address_town }}
                    {{ enquiry.property_address_region }}
                    {{ enquiry.property_address_postcode }}
                  </td>
                </tr>
                <tr>
                  <td>Landlord Full Name</td>
                  <td>{{ enquiry.landlord_full_name }}</td>
                </tr>
                <tr>
                  <td>Phone</td>
                  <td>{{ enquiry.phone }}</td>
                </tr>
                <tr>
                  <td>Phone 2</td>
                  <td>{{ enquiry.phone_2 }}</td>
                </tr>
                <tr>
                  <td>Email</td>
                  <td>{{ enquiry.email }}</td>
                </tr>
                <tr>
                  <td>Email 2</td>
                  <td>{{ enquiry.email2 }}</td>
                </tr>
                <tr>
                  <td>Agreement Type</td>
                  <td>{{ enquiry.agreement_type }}</td>
                </tr>
                <tr>
                  <td>Assignee</td>
                  <td>
                    <div v-if="enquiry.assignee">
                      {{ enquiry.assigne_user.full_name }}
                    </div>
                  </td>
                </tr>
                <tr>
                  <td>Due Date</td>
                  <td>
                    {{ enquiry.due_date ? formatDate(enquiry.due_date) : "" }}
                  </td>
                </tr>
                <tr>
                  <td>No of bedrooms</td>
                  <td>{{ enquiry.no_of_bedrooms }}</td>
                </tr>
                <tr>
                  <td>Rent Value</td>
                  <td>{{ enquiry.rent_value }}</td>
                </tr>

                <tr>
                  <td>Deposit Value</td>
                  <td>{{ enquiry.deposit_value }}</td>
                </tr>
                <tr>
                  <td>Commission</td>
                  <td>{{ enquiry.commission }}</td>
                </tr>
                <tr>
                  <td>Contact Method</td>
                  <td>{{ enquiry.contact_method }}</td>
                </tr>
                <tr>
                  <td>Current Provider</td>
                  <td>{{ enquiry.current_provider }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{{ enquiry.status }}</td>
                </tr>
                <tr>
                  <td>Lost Reason</td>
                  <td>{{ enquiry.lost_reason }}</td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>
        </v-col>

        <v-col cols="8">
          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Notes</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn
                    small
                    depressed
                    color="green lighten-4 green--text"
                    v-on="on"
                    @click="$refs.noteDialog.openForm(enquiry)"
                  >
                    <v-icon small>mdi-plus</v-icon>
                  </v-btn>
                </template>
                <span>Edit</span>
              </v-tooltip>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
              :headers="noteTableHeaders"
              :items="enquiry.notes"
              no-data-text="No Notes"
            >
              <template v-slot:item.note_text="{ item }">
                <span v-html="item.note_text"></span>
              </template>

              <template v-slot:item.created_at="{ item }">
                {{ formatDateTime(item.created_at, true) }}
              </template>
            </v-data-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Enquiry Checklist</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Item</th>
                  <th>Complete</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>

                <tr
                  v-for="item in enquiry_type"
                  v-bind:key="item.id"
                  v-bind:class="{
                    header: item.type === 'Section Header',
                  }"
                >
                  <th v-if="item.type === 'Standard'">
                    {{ item.name }}
                  </th>
                  <th colspan="4" v-if="item.type === 'Section Header'">
                    {{ item.name }}
                  </th>
                  <td v-if="item.type === 'Standard'">
                    <v-icon v-if="item.pivot.completed" color="green"
                      >mdi-check-circle
                    </v-icon>
                    <v-icon v-else color="red">mdi-close-circle </v-icon>
                  </td>
                  <td v-if="item.type === 'Standard'">
                    {{ item.pivot.notes }}
                  </td>
                  <td v-if="item.type === 'Standard'">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          @click="$refs.checkListDialog.openForm(5, item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6" v-if="enquiry_checklist.length > 0">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Checklist {{ enquiry.status }}</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-simple-table class="mb-5 table" divider="true">
              <tbody>
                <tr>
                  <th>Item</th>
                  <th>Complete</th>
                  <th>Notes</th>
                  <th>Actions</th>
                </tr>

                <tr
                  v-for="item in enquiry_checklist"
                  v-bind:key="item.id"
                  v-bind:class="{
                    header: item.type === 'Section Header',
                  }"
                >
                  <th v-if="item.type === 'Standard'">
                    {{ item.name }}
                  </th>
                  <th colspan="4" v-if="item.type === 'Section Header'">
                    {{ item.name }}
                  </th>
                  <td v-if="item.type === 'Standard'">
                    <v-icon v-if="item.pivot.completed" color="green"
                      >mdi-check-circle
                    </v-icon>
                    <v-icon v-else color="red">mdi-close-circle </v-icon>
                  </td>
                  <td v-if="item.type === 'Standard'">
                    {{ item.pivot.notes }}
                  </td>
                  <td v-if="item.type === 'Standard'">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on }">
                        <v-btn
                          small
                          depressed
                          color="blue lighten-4 blue--text"
                          v-on="on"
                          @click="$refs.checkListDialog.openForm(5, item)"
                        >
                          <v-icon small>mdi-pencil</v-icon>
                        </v-btn>
                      </template>
                      <span>Edit</span>
                    </v-tooltip>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-card>

          <v-card class="mb-6">
            <v-toolbar flat dark dense :color="appColor">
              <v-toolbar-title>Audit</v-toolbar-title>
              <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider></v-divider>

            <v-data-table
              :headers="tableHeaders"
              :items="enquiry.audits"
              no-data-text="No Audit"
              :items-per-page="-1"
            >
              <template v-slot:item.audit_date="{ item }">
                {{ formatDateTime(item.audit_date, true) }}
              </template>
            </v-data-table>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-dialog v-model="deleteForm.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Form</v-card-title>
        <v-card-text>Are you sure you wish to archive form?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteForm.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <enquiry-dialog ref="enquiryDialog" type="individual" />
    <NoteDialog ref="noteDialog" type="enquiry" />
    <CheckListDialog ref="checkListDialog" type="enquiry" />
  </div>
</template>

<script>
import EnquiryDialog from "./components/EnquiryDialog.vue";
import CheckListDialog from "../checklists/components/CheckListDetailDialog.vue";
import NoteDialog from "../notes/NoteDialog.vue";

export default {
  props: {
    appColor: {
      type: String,
    },
  },
  components: { CheckListDialog, NoteDialog, EnquiryDialog },

  data() {
    return {
      pdfLoading: false,
      deleteForm: {
        dialog: false,
        form: {},
        loading: false,
      },
      fields: {
        attending_viewing: false,
      },
      noteTableHeaders: [
        { text: "Date/Time", value: "created_at" },
        { text: "User", value: "user.full_name" },
        { text: "Note", value: "note_text" },
      ],

      tableHeaders: [
        { text: "Date/Time", value: "audit_date" },
        { text: "User", value: "user.full_name" },
        { text: "Activity", value: "audit_text" },
      ],
      breadcrumbs: [
        {
          text: "Enquiries",
          disabled: false,
          to: { name: "module-sbpm-enquiries" },
          exact: true,
        },
      ],
    };
  },

  computed: {
    enquiry() {
      let enquiry = this.$store.getters["sbpm/enquiriesStore/get"];

      return enquiry;
    },

    enquiry_checklist() {
      let enquiry2 = this.$store.getters["sbpm/enquiriesStore/get"];

      enquiry2 = enquiry2.checklists.filter(
        (c) =>
          c.checklist.checklist_code == "new-managed-let" ||
          c.checklist.checklist_code == "new-let-only"
      );

      return enquiry2;
    },

    enquiry_type() {
      let enquiry3 = this.$store.getters["sbpm/enquiriesStore/get"];

      enquiry3 = enquiry3.checklists.filter(
        (c) => c.checklist.checklist_code == "enquiry"
      );

      return enquiry3;
    },

    applications2() {
      let applications2 = this.enquiry.property.applications;

      applications2 = applications2.filter(
        (c) =>
          c.status.status_desc == "Referencing in Progress" ||
          c.status.status_desc == "Landlord Advised of Entry Date" ||
          c.status.status_desc == "With Landlord" ||
          c.status.status_desc == "Accepted"
      );

      return applications2;
    },
  },
  methods: {
    openDelete(form) {
      this.deleteForm.form = form;
      this.deleteForm.dialog = true;
    },

    resetDelete() {
      this.deleteForm.dialog = false;
      this.deleteForm.form = {};
      this.deleteForm.loading = false;
    },

    saveDelete() {
      this.deleteForm.loading = true;

      this.$store
        .dispatch("sbpm/propertiesStore/deleteForm", {
          appId: this.$route.params.id,
          formId: this.deleteForm.form.application_id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteForm.loading = false;
        });
    },
  },
};
</script>
<style scoped>
tr.header {
  background-color: #f5f5f5;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 1rem;
}
</style>
